.review-container {
	justify-content: flex-start;
	padding: 20px;
	width: 90vw;
	.splide__list {
		max-height: 200px !important;
		direction: ltr !important;
	}
}

.review-heading {
	color: #333333;
	font-weight: 600;
}

.review-card {
	padding: 30px;
	border-radius: 10px;
	border: 2px solid #b3b3b3;
	margin: 10px;
	max-height: 160px;
	overflow-y: auto;
	@media (min-width: 1000px) {
		width: 20vw;
	}
}

.profile-picture {
	border-radius: 100px;
}

.review-info-container {
	display: flex;
	justify-content: start;
}

.review-text {
	font-size: 14px;
	color: #6f6e6e;
}

.row-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.single-review-container {
	border: 1px solid #b3b3b3;
	border-radius: 10px;
	margin-right: 20px;
	padding: 12px;
	max-height: 130px;
	height: 100%;
	overflow-y: auto;
	.single-review-header {
		display: flex;
		align-items: center;

		h4 {
			margin: 0;
		}
		p {
			margin: 0;
		}
	}
	.merchant-initials {
		border: 1px solid rgb(179, 179, 179);
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 14px;
	}
}

.name-initials {
	border: 1px solid #b3b3b3;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
