.search-input {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;

	&:focus {
		box-shadow: none !important;
	}

	input {
		background: #f5f5f5 !important;
		font-size: 12px !important;
		color: #0f2147 !important;

		&::placeholder {
			color: #b7bdc6 !important;
		}
	}
}
