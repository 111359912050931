.order-life-cycle-state-main-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #ffffff;
	padding: 20px 0px;
	.vertical-seperator {
		border: none;
		border-left: 1px solid gray;
		opacity: 0.5;
		height: 18px;
		margin: 0 5px;
	}
	.order-life-cycle-state-wrapper {
		max-width: 1200px;
		width: 100%;
		padding: 10px;
		display: flex;
		justify-content: space-between;

		.details-left-side {
			display: flex;
			justify-content: space-between;
		}
		.assigne {
			width: 265px;
			flex-shrink: 0;
			background-color: #fef7d9;
			border: 1px dashed black;
			border-radius: 5px;
		}
		.reassignBtn {
			display: inline-flex;
			padding: 0 7px 0 8px;
			justify-content: center;
			align-items: center;
			border-radius: 3px;
			border: none;
			height: 30px;
			color: white;
			background: linear-gradient(180deg, #00afff, #07f 100%);
			margin: 10px;
			&:disabled {
				opacity: 0.5;
				background-color: rgb(163, 163, 163);
			}
		}
		@media (max-width: 700px) {
			flex-direction: column;
		}
	}
}
