.navbar-container {
	.actionBtns {
		.ant-btn:hover {
			color: black !important;
		}
	}
}
.user-menu-details {
	display: flex;
	align-items: center;

	.user-menu-details-initials {
		width: 30px;
		height: 30px;
		border: 1px solid #0f2147;
		color: black;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-inline-end: 8px;
		font-weight: 700;
	}

	.user-menu-details-content {
		.country-flag {
			width: 22px;
			margin-left: 5px;
		}

		p {
			color: #0f2147;
			margin: 0;
			line-height: 1;

			&.name {
				font-weight: 700;
			}

			&.email {
				font-size: 12px;
			}
		}
	}
}
