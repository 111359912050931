@import '../assets/styles/variables';

.side-panel-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 64px;

	.side-panel-logo {
		width: 100%;
		max-width: 140px;
		padding: 0 10px;
	}
}

.inactive-alert-container {
	margin: 20px;
}

.user-details-spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 200px;
}

.ant-layout-footer {
	padding: 8px 0 !important;

	.footer-content {
		margin: 0;
		font-size: 11px;

		&.capture {
			font-size: 10px;
			margin: 4px;
		}
	}
}

.ant-layout-header {
	height: 46px !important;
}

.main-header {
	.ant-menu-overflow-item {
		display: flex !important;
		align-items: center !important;
		height: 46px !important;
		line-height: 2 !important;
	}
}

.logout-button {
	position: absolute;
	top: 17px;
	font-size: 14px;
	stroke-width: 75;
	stroke: #ffffff;
	z-index: 100002;

	&:hover {
		stroke: #ff0000;
	}
}

.ant-layout.site-layout {
	overflow-x: hidden;
}

.ant-menu-vertical {
	border: 1px solid transparent !important;
}

:where(.css-dev-only-do-not-override-vlu36u).ant-menu {
	font-size: 12px !important;
}

.ant-tag {
	margin: 4px !important;
}

.ant-menu-vertical.ant-menu-sub {
	margin: 0 !important;
}

.rtl {
	.logout-button {
		left: 14px;
	}

	.languages-button {
		left: 34px;
	}

	.signin-section,
	.forgot-password-section {
		.lang-container {
			justify-content: left;
		}
	}

	.ant-timeline .ant-timeline-item-head-custom {
		transform: translate(50%, -50%) !important;
	}

	.ant-layout-header {
		@media #{$media-sm, $media-md, $media-lg, $media-xl} {
			padding: 0 0 0 62px !important;
		}

		@media #{$media-xs} {
			padding: 0 62px !important;
		}
	}
}

.ltr {
	.logout-button {
		right: 14px;
	}

	.languages-button {
		right: 34px;
	}

	.signin-section,
	.forgot-password-section {
		.lang-container {
			justify-content: right;
		}
	}

	.ant-timeline .ant-timeline-item-head-custom {
		transform: translate(-50%, -50%) !important;
	}

	.ant-layout-header {
		@media #{$media-sm, $media-md, $media-lg, $media-xl} {
			padding: 0 62px 0 0 !important;
		}

		@media #{$media-xs} {
			padding: 0 62px !important;
		}
	}
}

.ant-tabs-nav-wrap,
.ant-tabs-nav-wrap * {
	direction: ltr;
	text-align: left;
}

.ant-tabs .ant-tabs-tab {
	width: 60px !important;
	padding: 8px !important;
	margin: 0 10px !important;
}

.ant-menu-item {
	border: 1px solid transparent !important;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
	@media #{$media-xs, $media-sm, $media-md} {
		top: -40px !important;
		background-color: transparent !important;
	}
}

.ant-layout .ant-layout-sider-has-trigger {
	@media #{$media-xs} {
		width: 100% !important;
		min-width: 100% !important;
	}
}

.ant-table-content {
	overflow-x: auto;

	table {
		border: none !important;
	}
	.ant-table-tbody > tr.ant-table-row:hover > td,
	.ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background-color: #edf0ff;
	}

	th.ant-table-cell {
		background-color: #e9e9e9;
	}
}
