@import '../../assets/styles/mixin';

.single-client {
	max-width: 1200px;
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: auto;

	.header {
		background-color: white;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.section-container {
		display: flex;
		justify-content: center;
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.section {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;

		&:first-of-type {
			@media (max-width: 768px) {
				margin-bottom: 10px;
				width: 100%;
			}
		}

		@media (max-width: 768px) {
			border: 1px solid #b3b3b3;
			border-radius: 6px;
			width: 100%;
		}
	}

	.horizontal-separator {
		border: 0.5px solid #b3b3b3;
		width: 70%;
	}

	.full-width-separator {
		margin: 0 2px;
		width: 100%;
		max-width: 800px;

		@media (min-width: 768px) {
			border: 0.5px solid #b3b3b3;
		}
	}

	.profile-icon {
		border: 2px solid #b3b3b3;
		border-radius: 900px;
		width: 80px;
		height: 80px;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.profile-icon-text {
		font-size: x-large;
		color: #333333;
		font-weight: bold;
	}

	.center-container {
		justify-content: center;
		display: flex;
		align-items: center;
	}

	.text-card {
		flex-direction: column;
		justify-content: flex-start;
		display: flex;
		width: 100%;
		padding: 0 20px 20px 20px;

		p {
			word-break: break-word;
		}
	}

	.text-container {
		width: 100%;
		justify-content: space-between;
		display: flex;
		margin-top: 8px;
	}

	.heading {
		font-size: 20px;
		color: #000000;
		font-weight: 600;
		margin-bottom: 0;
	}

	.info {
		font-size: 16px;
		color: #333333;
		font-weight: 400;
	}

	.content {
		font-size: 16px;
		color: black;
		font-weight: 500;
	}

	.profile-picture {
		border-radius: 100px;
	}

	.name-initials {
		border: 1px solid #b3b3b3;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
