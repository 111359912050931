.body-details-wrapper {
	width: 100%;
	max-width: 550px;

	@media (max-width: 1300px) {
		max-width: 450px;
	}
	@media (max-width: 700px) {
		max-width: 200px;
	}

	@media (max-width: 575px) {
		max-width: 100%;
	}

	.order-details-wrapper {
		border-bottom: 1px solid #dbdbdb;
		margin-bottom: 16px;
		padding: 0 0 8px 0;
		.uploadedFile {
			border: 1px solid #018aff;
			width: 130px;
			height: 30px;
			background-color: #eaecef;
			color: #018aff;
			cursor: pointer;
		}

		.details-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 4px;
			margin: 20px 0px;
			.value {
				font-weight: 700;
				white-space: nowrap;
			}
		}
	}
}
