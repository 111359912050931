.reviews-container {
	.profile-icon {
		border: 2px solid #b3b3b3;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.grid-container {
		grid-template-columns: auto auto auto;
		padding: 10px 0px;
	}
	.grid-item {
		border-right: 1px solid rgba(179, 176, 176, 0.8);
		padding: 20px;
		font-size: 30px;
		text-align: center;
		@media (min-width: 300px) and (max-width: 639px) {
			border: 1px solid #b3b3b3;
			margin: 2px;
			margin: 40px;
		}
	}
	.info-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10px;
	}
}
