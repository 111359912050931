.timer-container {
	display: flex;
	align-items: center;

	span {
		background: linear-gradient(#00afff, #0077ff);
		color: #ffffff;
		border-radius: 4px;
		width: 32px;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 4px;
		font-weight: 500;
	}
}
