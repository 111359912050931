.order-life-cycle-header-main-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #f5f5f5;

	.order-life-cycle-header-wrapper {
		max-width: 1200px;
		width: 100%;
		padding: 20px 20px 10px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 700px) {
			flex-direction: column;
		}

		.order-life-cycle-left-side {
			.order-life-cycle-details {
				display: flex;
				align-items: center;

				.order-life-cycle-type-status {
					color: #ffffff;
					font-weight: 700;
					font-size: 12px;
					border-radius: 4px;
					margin-right: 8px;
					display: flex;
					align-items: center;
					padding: 0 10px;
					max-height: 30px;
					display: flex;
					width: 73px;
					height: 34px;
					padding: 4px 16px 4px 17px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
				}
			}

			.order-life-cycle-description {
				max-width: 300px;
				font-size: 12px;
				margin: 8px 0;
			}
		}

		.order-life-cycle-right-side {
			font-size: 14px;

			.order-life-cycle-right-side-row {
				display: flex;
				margin-bottom: 8px;
				justify-content: right;

				@media (max-width: 700px) {
					justify-content: left;
				}

				.label {
					font-weight: 500;
					margin-right: 8px;
					color: #707172;
				}
			}
		}
	}
}
