.checkBox-container {
	& .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #0094ff;
		border-color: #0094ff;
	}
	& .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #0094ff !important;
		border-color: #0094ff !important;
	}
}
