@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.page-sub-header-cover {
	background-image: url(./assets/images/backgroundHeader.jpg);
}

.bg-gradient-primary {
	background: var(--blue, linear-gradient(180deg, #00afff 0%, #07f 100%));
}

.ant-notification-notice-close {
	top: 10px !important;
	right: 5px !important;
	&:dir(rtl) {
		left: 10px !important;
	}
}
.ant-table-cell-row-hover {
	background-color: #d6f1ff !important;
}

.ant-table-pagination {
	margin-top: 20px !important;
}

.ant-collapse-header {
	padding: 5px 10px !important;
}
:where(.css-dev-only-do-not-override-1nz6lwh).ant-input-rtl {
	direction: ltr !important;
}

.rtl {
	font-family: 'Cairo', sans-serif !important;
	font-optical-sizing: auto;
	font-weight: 100;
	font-style: normal;
	font-weight: 400;
	font-variation-settings: 'slnt' 0;
}
.rtl [class^='ant-'],
.rtl [class*=' ant-'] {
	font-family: 'Cairo', sans-serif !important;
}

.word-break-column {
	word-break: break-word; /* Ensures long text wraps */
	white-space: normal; /* Allows wrapping to multiple lines */
}

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.ant-table-thead {
	@media (max-width: 699px) {
		display: none; /* Hides the header row */
	}
}
.rtl button {
	font-family: 'Cairo', sans-serif !important;
}
