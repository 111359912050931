.user-list-container {
	.assignBtn {
		display: inline-flex;
		padding: 0 7px 0 8px;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		border: none;
		height: 30px;
		width: 80px;
		color: white;
		background: linear-gradient(180deg, #00afff, #07f 100%);
	}
	.selected-row {
		background-color: #d6f1ff; /* Set your desired background color */
	}
}
