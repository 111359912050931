.order-life-cycle-body-main-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #ffffff;

	.order-life-cycle-body-wrapper {
		max-width: 1200px;
		min-height: 444px;
		width: 100%;
		padding: 10px;
		display: flex;
		position: relative;
		justify-content: space-between;
	}
}
