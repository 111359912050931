.countries-and-paym-container {
	.blue-grad-bg {
		background: var(--blue, linear-gradient(180deg, #00afff 0%, #07f 100%));
	}

	.ant-select-selection {
		background-color: red !important;
	}

	.ant-table-column-sorters {
		display: inline-flex !important;
		flex-grow: 0 !important;
	}

	.ant-table-thead > tr > th {
		background: #f5f5f6 !important;
		border-bottom: 0px !important;
	}

	.search-input {
		border: none !important;
		outline: none !important;
		box-shadow: none !important;

		&:focus {
			box-shadow: none !important;
		}

		input {
			background: #f5f5f5 !important;
			font-size: 12px !important;
			color: #0f2147 !important;

			&::placeholder {
				color: #b7bdc6 !important;
			}
		}
	}

	.ant-select-item-option-selected {
		background-color: rgba(100, 100, 100, 0.2) !important;
	}
}
