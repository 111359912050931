.actions-corner-container {
	margin: 20px 0px;
	.buttons-container {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
		margin-bottom: 10px;
		line-height: 1.2rem;
		button {
			border-radius: 4px;
			cursor: pointer;
			&.reorder,
			&.payment-received,
			&.payment-completed,
			&.finished-successfully {
				background-color: #00afff;
				color: #ffffff;
			}

			&.accept {
				background-color: #33a867;
				border-color: #33a867;
				color: #fff;
			}

			&.reject {
				background-color: #f6465d;
				border-color: #f6465d;
				color: #fff;
			}

			&:disabled {
				cursor: not-allowed;
				border-color: #d9d9d9;
				color: rgba(0, 0, 0, 0.25);
				box-shadow: none;
				background: #f0f0f0;
			}
		}
	}

	.ant-alert-description {
		ul {
			padding: 0 !important;
		}
	}
}
