.button-container {
	display: flex;
	justify-content: end;
}

@media (min-width: 993px) {
	.small-screen-row {
		display: none;
	}
}

@media (min-width: 993px) {
	.country-and-paym-table-col {
		flex-direction: row;
	}
}

@media (max-width: 404px) {
	.country-and-paym-table-col {
		flex-direction: column;
		padding: 10px;
	}

	.country-and-paym-container {
		justify-content: start;
	}
}
