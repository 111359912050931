@import '../../assets/styles/mixin';

.home-container {
	#chart2 text {
		direction: ltr !important;
	}
	.c3-chart-arc {
		text {
			// keep it for now in the design it doesnt exist
			// but that doesnt make sense not to show the values of
			// the shares
			// visibility: hidden;
			color: white;
		}
	}
	.c3-legend-item-Buy {
		text {
			font-weight: bold;
		}
	}
	.c3-legend-item-Sell {
		text {
			font-weight: bold;
		}
	}
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 1rem;

	.date-range {
		margin-right: 8px !important;
	}

	.home {
		width: 100%;
		max-width: 1450px;
		.filters-container {
			margin-bottom: 10px;

			.ant-form-item {
				margin: 0 !important;
			}
		}

		.statistics-cards-container {
			display: grid;
			width: 100%;
			grid-column-gap: 10px;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			margin-bottom: 20px;

			.statistics-card {
				display: flex;
				max-width: 19%;
				flex-direction: column;
				align-items: center;
				padding: 10px;

				.value {
					font-size: 24px;
					font-weight: 700;
					margin: 0;

					&.active {
						color: #eb8309;
					}
					&.total-orders {
						color: #00afff;
					}
					&.total-merchants {
						color: #ffd600;
					}
					&.total-clients {
						color: #f11010;
					}
				}

				.label {
					font-size: 14px;
					margin: 0;
				}
			}
		}

		.charts-container {
			gap: 0.5rem;
			grid-template-columns: repeat(3, 1fr) 1.3fr;
			.grid-item1 {
				grid-column: span 3;
			}
			.grid-item2 {
				grid-column: span 1;
			}
			.area-chart {
				.c3-shape.c3-area.c3-area-sell,
				.c3-shape.c3-area.c3-area-buy {
					opacity: 0.5 !important;
				}

				tspan {
					font-size: 11px !important;
				}
			}

			.c3 path,
			.c3 line {
				fill: none;
				stroke: transparent;
			}

			.c3-legend-item {
				cursor: default !important;
			}

			.c3-line {
				stroke-width: 2px;
			}

			.c3-shapes.c3-shapes-sell.c3-circles.c3-circles-sell {
				fill: #f6465d !important;
			}
			.c3-shapes.c3-shapes-buy.c3-circles.c3-circles-buy {
				fill: #33a867 !important;
			}

			.c3 .c3-grid line {
				stroke: #cccccc;
				stroke-width: 2;
				opacity: 0.5;
			}

			.tooltip-container {
				border: 2px solid #00afff;
				border-radius: 8px;
				background-color: #e8f7ff;
				color: rgb(0, 51, 95);
				padding: 2px 8px;
				box-shadow: 5px 5px 5px #3c7894;

				.tooltip-text {
					margin: 0;
					font-weight: 500;
					font-size: 12px;

					.value {
						font-weight: 700;

						&.buy {
							color: #33a867;
						}
						&.sell {
							color: #f6465d;
						}
					}
				}
			}
		}

		.ant-card-body {
			padding: 0 !important;
		}
	}
}
.top-cards {
	.ant-card-body {
		padding: 0 !important;
		width: 100% !important;
		height: 100% !important;
		display: flex !important;
	}
}
