.order-history-wrapper {
	.order-history-title {
		font-size: 26px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
	}
	.logMessage {
		max-width: 300px;
		word-wrap: break-word;
	}
}
