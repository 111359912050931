.charts {
	.ant-card .ant-card-body {
		padding: 15px !important;
	}
}
.charts-container {
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr 1fr;
	.grid-item1 {
		grid-column: span 2;
	}
	.grid-item2 {
		grid-column: span 1;
	}
	.area-chart {
		.c3-shape.c3-area.c3-area-sell,
		.c3-shape.c3-area.c3-area-buy {
			opacity: 0.5 !important;
		}

		tspan {
			font-size: 11px !important;
		}
	}
	.tooltip-container {
		border: 2px solid #00afff;
		border-radius: 8px;
		background-color: #e8f7ff;
		color: rgb(0, 51, 95);
		padding: 2px 8px;
		box-shadow: 5px 5px 5px #3c7894;

		.tooltip-text {
			margin: 0;
			font-weight: 500;
			font-size: 12px;

			.value {
				font-weight: 700;

				&.buy {
					color: #33a867;
				}
				&.sell {
					color: #f6465d;
				}
			}
		}
	}
}

.lastChart {
	margin-top: 10px;
	.ant-card-body {
		display: flex !important;
		justify-content: space-between;
	}
}
.select-box {
	.ant-select-selection-item {
		background-color: #dff5ff;
		color: #00afff;
	}
	::-webkit-scrollbar {
		width: 8px;
	}
	::-webkit-scrollbar-thumb {
		/* Foreground */
		background: #61686b !important;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		/* Background  */
		background: #b8babb !important;
		border-radius: 10px;
	}
	.ant-select-selector {
		max-height: 60px;
		overflow-y: auto;
	}
}
